import React, { useState } from 'react';
import Login from './UserComponent/Login';
import Register from './UserComponent/Register';
import HomeView from './HomeviewComponent/Homeview';
import Footer from './FooterComponent/Footer';
import Search from './Search Component/Search';
import Navbar from './NavbarComponent/Navbar';
import About from './AboutComponent/About';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { UserProvider } from './UserComponent/UserContext';
import './app.css'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (status) => {
    setIsLoggedIn(status);
  }

  return (
    <UserProvider>
      <Router>
        <div className="App">
          <Navbar isLoggedIn={isLoggedIn} onLogin={handleLogin} />
          <div className="content">
            <Switch>
              <Route path="/" exact component={HomeView} />
              <Route path="/register" component={Register} />
              <Route
                path="/login"
                render={(props) =>
                  isLoggedIn ?
                    <Redirect to="/search" /> :
                    <Login {...props} onLoginSuccess={() => handleLogin(true)} />
                }
              />
              <Route path="/search" component={Search} />
              <Route path="/about" component={About} />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    </UserProvider>
  );
};


export default App;
