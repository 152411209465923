import React, { useState, useEffect } from "react";
import "../Search Component/search.css";
import Navbar from "../NavbarComponent/Navbar";
import Leaderboard from "../Top Tracks Component/Leaderboard";

export default function Search() {
    //Declaring state variables to update the data and to re render it whenever a change happens
    const [query, setQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    //Creating a function that will be called whenever user types in the search bar and will update
    //the 'query' state with the current input value and will reset the 'error' state to null
    const handleInputChange = (e) => {
        setQuery(e.target.value);
        setError(null);

    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    //Creating an asynchronous function to checking various conditions such as if the query is empty,
    //set loading state to true and clears the previous result and error state.
    const handleSearch = async () => {
        if (query.trim() === "") return;
        setLoading(true);
        setError(null);
        setSearchResults([]);

        //Retrieving the data from the server.js file
        const url = `http://localhost:5000/api/search/${encodeURIComponent(query)}`;
        try {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            const tracks = data.response.hits;

            if (tracks && tracks.length > 0) {
                setSearchResults(tracks);
                saveResults(tracks);
            } else {
                setError('No results found');
            }
        } catch (error) {
            setError('An error occurred while searching. Please try again.')
            console.log('Search error: ', error);
        } finally {
            setLoading(false);
        }
    };
    //Creating a function to save the data to a local server with the implementation of try block statement in which 
    //search result is mapped and formatted into a new array and then fetching a requests to a local server to save the 
    //formatted search result and then the formatted data is converted into string to be included in the request body and 
    //using different throw statements to display the error on the console 
    const saveResults = async (hits) => {
        try {
            const formattedTracks = hits.map(hit => ({
                artistName: hit.result.primary_artist.name,
                trackName: hit.result.title,
                albumCoverUrl: hit.result.song_art_image_url,
                lyrics: {
                    url: hit.result.url,
                },

            }));
            const response = await fetch('http://localhost:8080/melodyMap/saveSearch', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                mode: 'no-cors',
                body: JSON.stringify(formattedTracks)
            });
            if (!response.ok) {
                throw new Error('Failed to save the results')
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    //This useEffect hook is invoked when the component mounts. It defines and calls an asynchronous fetchSearchResults 
    //function to retrieve previous search results. The loading state is set to true initially and then it fetches from 
    //the previous search results from a local server and then loading state is set to false to show that the fetching is completed
    useEffect(() => {
        const fetchSearchResults = async () => {
            setLoading(true);
            try {
                const response = await fetch('http://localhost:8080/melodyMap/getSearchResults');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSearchResults(data);
            } catch (error) {
                console.error('Error', error);
                // setError('Failed to fetch search results');
            } finally {
                setLoading(false);
            }
        }
        fetchSearchResults();
    }, []);

    // Rendering components with inclusion of Input text and a button that has onClick functionality to request a
    //response from the API. The response then gets filtered with conditional rendering to display the result if
    //the property is available in the data that we need to render. We are mapping over the 'searchResults' array to display each item.
    return (
        <div className="search-container">
            <Navbar />
            <h1>Search Your Melody</h1>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Enter your search query"
                    value={query}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}

                />

                <Leaderboard />

            </div>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            <ul className="results">
                {searchResults.map((hit, index) => (
                    hit.result ? (
                        <li key={index}>
                            {hit.result.song_art_image_url && (
                                <img
                                    src={hit.result.song_art_image_url}
                                    alt="Album Cover"
                                    className="album-cover"
                                />
                            )}
                            <p><strong>Title:</strong> {hit.result.title}</p>
                            <p><strong>Artist:</strong> {hit.result.primary_artist.name}</p>
                            <a href={hit.result.url} target="_blank" rel="noopener noreferrer">
                                Read Lyrics
                            </a>
                        </li>
                    ) : null
                ))}
            </ul>
        </div>
    );
}