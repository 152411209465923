import React, { useState } from "react";
import '../UserComponent/user.css';
import { LOGIN_URL } from "./Constants";
import { Link, useHistory } from "react-router-dom";
import { useUser } from "./UserContext";
import Navbar from "../NavbarComponent/Navbar";

export default function Login() {
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { setIsLoggedIn } = useUser();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user || !pass) {
            setErrMsg('Both fields are required');
            return;
        }



        setLoading(true);

        try {
            const response = await fetch(LOGIN_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userName: user, password: pass }),
            });

            setLoading(false);

            if (response.ok) {
                setIsLoggedIn(true);
                history.push("/search");
            } else {
                const data = await response.json();
                setErrMsg(data.message || 'Login Failed');
            }
        } catch (error) {
            setLoading(false);
            setErrMsg('An error occurred. Please try again later');
        }
    }

    return (
        <div className="auth-container">
            <Navbar />
            {success ? (
                <div className="success-message">
                    <h1>Welcome Back!</h1>
                    <p>You have successfully logged in.</p>
                </div>
            ) : (
                <form className="auth-form" onSubmit={handleSubmit}>
                    {errMsg && <p className="error-message">{errMsg}</p>}

                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input type="text" id="username" value={user} onChange={(e) => setUser(e.target.value)} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input type="password" id="password" value={pass} onChange={(e) => setPass(e.target.value)} required />
                    </div>

                    <button type="submit" className="auth-button" disabled={loading}>
                        {loading ? "Logging in..." : "Login"}
                    </button>
                    <p className="register-link">
                        Don't have an account?&ensp; <Link to="/register">Register Here</Link>
                    </p>
                    <br></br>
                    <p className="register-link">
                        <Link to="/">Home Page</Link>
                    </p>
                </form>
            )}
        </div>

    );
}
