import React, { useState, useEffect } from "react";
import { Select } from 'antd';
import LyricsSearch from './LeaderboardSearch';
import './leaderboard.css';

const selectOptions = [
    { value: 'US', label: 'US' },
    { value: 'GB', label: 'GB' },
    { value: 'FR', label: 'FR' },
    { value: 'JP', label: 'JP' },
    { value: 'CA', label: 'CA' },
];

export default function Leaderboard() {
    const [res, setRes] = useState([]);
    const [country, setCountry] = useState('US');

    useEffect(() => {
        fetchData(country);
    }, [country]);

    const fetchData = async (value) => {
        const url = `/api/trending/${value}/top`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setRes(data.message.body.track_list);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const onChange = (value) => {
        setCountry(value);
    };

    return (
        <div className="Leaderboard">

            <h2>Top Trending Songs</h2> <Select
                showSearch
                placeholder="Select a Region"
                optionFilterProp="children"
                onChange={onChange}
                options={selectOptions}
            />
            <LyricsSearch results={res} />
        </div>
    );
}
