import React from "react";
import { useHistory } from 'react-router-dom';
import "./about.css";
import HaroonJohnImg from '../AboutComponent/images/haroon.jpg';
import JamesAhmadImg from '../AboutComponent/images/james.png';

function About() {
    const history = useHistory();

    return (
        <div className="about-container">
            <h1>About Melody Map</h1>
            <p>
                Melody Map is a revolutionary music platform that allows users to explore and discover new melodies from around the world. Founded by a team of passionate developers, our mission is to bring the joy of music to everyone.
            </p>
            <h2>Our Team</h2>
            <ul className="team-list">
                <li>
                    <img src={HaroonJohnImg} alt="Haroon John" className="team-img" />
                    Haroon John - Back-End Developer
                </li>
                <li>
                    <img src={JamesAhmadImg} alt="James Ahmad" className="team-img" />
                    James Ahmad - Front-End Developer
                </li>
            </ul>
            <button className="about-button" onClick={() => history.goBack()}>Go Back</button>
        </div>
    );
}

export default About;
