import React from "react";
import { Avatar, List } from 'antd';

const LeaderboardSearch = ({ results }) => {
  if (!results || results.length === 0) {
    return <div className='LeaderboardSearch-container'>No results found.</div>;
  }

  return (
    <div className='LeaderboardSearch-container'>
      <div className="LeaderboardSearch-scrollable">
        <List
          itemLayout="vertical"
          dataSource={results}
          renderItem={(data, index) => (
            <List.Item key={data.track.track_id || index} className="LeaderboardSearch-item">
              <List.Item.Meta
                avatar={<Avatar>{data.track.artist_name.charAt(0)}</Avatar>}
                title={
                  <a href={data.track.track_share_url} target="_blank" rel="noopener noreferrer">
                    {data.track.artist_name + ' - ' + data.track.track_name}
                  </a>
                }
                description={'Album: ' + data.track.album_name}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default LeaderboardSearch;
