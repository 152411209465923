import React, { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../UserComponent/user.css';
import { USER_REGEX, PWD_REGEX, REGISTER_URL } from "./Constants";
import { Link } from 'react-router-dom';

export default function Register() {
    const userRef = useRef();
    const [user, setUser] = useState('');
    const [validUserName, setValidUserName] = useState(false);
    const [pass, setPass] = useState('');
    const [validPass, setValidPass] = useState(false);
    const [matchPass, setMatchPass] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [userFocus, setUserFocus] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setValidUserName(USER_REGEX.test(user));
        setValidPass(PWD_REGEX.test(pass));
        setValidMatch(pass === matchPass && pass.length > 0);
    }, [user, pass, matchPass]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validUserName || !validPass || !validMatch) {
            setErrMsg('Please correct the errors before submitting.');
            return;
        }

        try {
            const response = await fetch(REGISTER_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userName: user, password: pass }),
            });

            if (response.ok) {
                setSuccess(true);
            }
            else if (response.status === 409) {
                setErrMsg('Username already taken. Please choose another.')
            } else {
                const data = await response.json();
                setErrMsg(data.message || 'Registration Failed');
            }
        } catch (error) {
            setErrMsg('An error occurred. Please try again later.');
        }
    }

    return (
        <div className="auth-container">
            {success ? (
                <div className="success-message">
                    <h1>Success!</h1>
                    <p>Account Created. <Link to="/login">Click here to Sign In</Link></p>
                </div>
            ) : (
                <form className="auth-form" onSubmit={handleSubmit}>
                    {errMsg && <p className="error-message">{errMsg}</p>}

                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <div className="input-wrapper">
                            <input type="text" id="username" ref={userRef} onChange={(e) => setUser(e.target.value)} onFocus={() => setUserFocus(true)} onBlur={() => setUserFocus(false)} required />
                            {validUserName ? <FontAwesomeIcon icon={faCheck} className="icon-valid" /> : <FontAwesomeIcon icon={faTimes} className="icon-invalid" />}
                        </div>
                        <p id="uidnote" className={userFocus && user && !validUserName ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            4 to 24 characters.<br />
                            Must begin with a letter.<br />
                            Letters, numbers, underscores, hyphens allowed.
                        </p>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <div className="input-wrapper">
                            <input type="password" id="password" onChange={(e) => setPass(e.target.value)} onFocus={() => setPwdFocus(true)} onBlur={() => setPwdFocus(false)} required />
                            {validPass ? <FontAwesomeIcon icon={faCheck} className="icon-valid" /> : <FontAwesomeIcon icon={faTimes} className="icon-invalid" />}
                        </div>
                        <p id="pwdnote" className={pwdFocus && !validPass ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters. <br />
                            Must include uppercase and lowercase letters, a number and a special character. <br />
                            Allowed special characters: ! @ # $ %
                        </p>
                    </div>

                    <div className="form-group">
                        <label htmlFor="confirm_pass">Confirm Password:</label>
                        <div className="input-wrapper">
                            <input type="password" id="confirm_pass" onChange={(e) => setMatchPass(e.target.value)} onFocus={() => setMatchFocus(true)} onBlur={() => setMatchFocus(false)}
                                required />
                            {validMatch ? <FontAwesomeIcon icon={faCheck} className="icon-valid" /> : <FontAwesomeIcon icon={faTimes} className="icon-invalid" />}
                        </div>
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                        </p>
                    </div>

                    <button type="submit" className="auth-button" disabled={!validUserName || !validPass || !validMatch}>Sign Up</button>

                    <p className="register-login-link">
                        Have an account?&ensp;  <Link to="/Login"> Sign in</Link>
                    </p>
                    <br></br>
                    <p className="register-link">
                        <Link to="/">Home Page</Link>
                    </p>

                </form>
            )}
        </div>
    );

}
