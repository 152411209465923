import React from "react";
import "./footer.css"

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <p>&copy; 2023 MelodyMap. All Rights Reserved.</p>
            </footer>
        );
    }
}

export default Footer;
