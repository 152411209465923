import React from "react";
import "./navbar.css";
import { Link, useHistory } from 'react-router-dom';
import { useUser } from "../UserComponent/UserContext";

function Navbar() {
    const { isLoggedIn, setIsLoggedIn } = useUser();
    const history = useHistory();

    const handleLogout = () => {
        setIsLoggedIn(false);
        history.push('/');
    }

    return (
        <div className="navbar">
            <Link to="/" className="nav-logo">Melody Map</Link>
            <div className="nav-items">
                {isLoggedIn ? (
                    <>
                        <span className="nav-item">Profile</span>
                        <span className="nav-item" onClick={handleLogout}>Sign Out</span>
                    </>
                ) : (
                    <>
                        <Link to="/login" className="nav-item">Login</Link>
                        <Link to="/register" className="nav-item">Register</Link>
                    </>
                )}
                <Link to="/about" className="nav-item">About</Link>

            </div>
        </div>
    );
}

export default Navbar;
