import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./homeview.css";
import Navbar from "../NavbarComponent/Navbar";
import image1 from "./images/1.jpg";
import image2 from "./images/2.jpg";
import image3 from "./images/3.jpg";
import image4 from "./images/4.jpg";
import image5 from "./images/5.jpg";
import image6 from "./images/6.jpg";
import image7 from "./images/7.jpg";
import image8 from "./images/8.jpg";
import image9 from "./images/9.jpg";
import image10 from "./images/10.jpg";
import image11 from "./images/11.jpg";
import image12 from "./images/12.jpg";
import image13 from "./images/13.jpg";
import image14 from "./images/14.jpg";
import image15 from "./images/15.jpg";
import image16 from "./images/16.jpg";
import image17 from "./images/17.jpg";
import image18 from "./images/18.jpg";
import image19 from "./images/19.jpg";
import image20 from "./images/20.jpg";
import image21 from "./images/21.jpg";
import image22 from "./images/22.jpg";


const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22];

class HomeView extends Component {
    renderImages() {
        return images.map((src, index) => (
            <div key={index} className="image-container">
                <img src={src} alt={`Melody-Map ${index + 1}`} className="home-image" />
            </div>
        ));
    }

    render() {
        return (
            <div className="container-fluid view">
                <Navbar />

                <div className="image-grid">
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                    {this.renderImages()}
                </div>

                <div className="quote-section">
                    <h6>
                        "When Words Fail, Music Speaks!"
                        <br />
                        "Free Your Mind, Let the Beats Bind!"
                    </h6>
                </div>

                <div className="search-section">
                    <h4>Let's Find Your Jam!</h4>
                    <i className="fas fa-music bounce" />
                    <Link to="/login">
                        <button className="search-button">
                            <i className="fas fa-angle-double-right" /> Dive In
                        </button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default HomeView;


